import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../../components/layout'
import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

import './monetization.css'
import desktopImage from '../../assets/old-images/monetization/notebook.png'
import streamImage from '../../assets/old-images/monetization/stream-list.png'
import ad1Image from '../../assets/old-images/monetization/ad1.png'
import ad2Image from '../../assets/old-images/monetization/ad2.png'
import ad3Image from '../../assets/old-images/monetization/ad3.png'

class Monetization extends React.Component {
  render() {
    return (
      <Page
        className={classNames('monetization', this.props.className)}
        headerTitle="Monetize Your Audience"
        headerContent={headerContent}
      >
        <PageSection className={classNames('ad-breaks')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>How It Works</h1>
                <p className={classNames('section-text')}>
                  Live365 inserts ads for you at intervals of your choosing.
                  Each ad break can be 30, 60, 90, or 120 seconds long. A
                  minimum of 4 minutes per hour is required, but you may run
                  more ads to maximize your revenue. Impressions and revenue are
                  reported directly into your admin for transparent reporting
                  and billing. We even provide a method for your DJs to insert
                  ads in their live shows.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img src={streamImage} alt="Ad breaks in the stream" />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames('max-revenue')}
          style={{ backgroundColor: '#F7F7F7' }}
        >
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('reverse-row', 'padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>Maximum Revenue</h1>
                <p className={classNames('section-text')}>
                  Live365’s Private Marketplace (PMP) allows buyers to
                  transparently buy audio ad inventory across the platform and
                  compete for the highest amount of revenue possible.
                </p>
              </Fade>
            </div>
            <div className={classNames('image-group')}>
              <Fade bottom duration={700} delay={300}>
                <img src={ad1Image} alt="an ad" />
                <img src={ad2Image} alt="an ad" />
                <img src={ad3Image} alt="an ad" />
              </Fade>
            </div>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames(
            'light-on-dark',
            'no-ads',
            'with-background-image'
          )}
        >
          <div className={classNames('column', 'padded-section-content')}>
            <Fade top duration={700} fraction={0.9}>
              <h1 className={classNames('section-title')}>
                Not interested in running ads?
              </h1>
              <p className={classNames('section-text')}>
                Choose one of our “No Ads” packages to remove monetization from
                your account.
              </p>
            </Fade>
          </div>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

const headerContent = (
  <RowLayout isResponsive itemSpacing={'4vw'}>
    <img src={desktopImage} alt="Live365 station" />

    <p className={classNames('body-text')}>
      Live365’s ad insertion solution enables broadcasters to maximize their
      revenue for their audience. Live365 is connected to the largest ad
      exchanges and trading desks, as well as directly representing inventory to
      buyers. The net result? We give you the maximum revenue for your
      listeners.
    </p>
  </RowLayout>
)

export default Monetization
